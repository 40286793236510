html { font-family: "Inter var", "system-ui"; }

@supports (font-variation-settings: normal) {
  html { font-family: "Inter var", "system-ui"; }
}

@tailwind base;

@layer base {
  body {
    font-family: "Inter var", sans-serif;
    -webkit-font-smoothing: antialiased;
  }
}

@tailwind components;
@tailwind utilities;

.ck.ck-editor__main > .ck-editor__editable {
  min-height: 180px;
  overflow-y: auto;
}

.ck.ck-toolbar,
.ck.ck-editor__main > .ck-editor__editable {
  border-color: #e7e7e7 !important;
  border-radius: 6px !important;
  background: #f5f5f5 !important;
}

.ck.ck-editor__main > .ck-editor__editable h1,
.ck.ck-editor__main > .ck-editor__editable h2,
.ck.ck-editor__main > .ck-editor__editable h3,
.ck.ck-editor__main > .ck-editor__editable p {
  font-size: 13px;
  line-height: 24px;
  color: #5e6565;
  line-height: initial;
  margin-bottom: 10px;
}

.ck.ck-editor__main > .ck-editor__editable.ck-focused {
  box-shadow: none !important;
  border-color: rgb(132 179 196) !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ck-error-container .ck.ck-toolbar {
  @apply !border-t-error !border-l-error !border-r-error;
}

.ck-error-container .ck.ck-editor__main > .ck-editor__editable {
  @apply !border-t-[#e7e7e7] !border-b-error !border-l-error !border-r-error;
}

.loading-complete_svg__checkmark {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: block;
  stroke-width: 1;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #6ab8a5;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.loading-complete_svg__checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke: #3fa68d;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.loading-complete_svg__checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.loading-complete-white_svg__checkmark {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: block;
  stroke-miterlimit: 10;
  animation: scale 0.3s ease-in-out 0.9s both;
}

.loading-complete-white_svg__checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.loading-complete-white_svg__checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #6ab8a5;
  }
}

@keyframes fill-white {
  100% {
    box-shadow: inset 0px 0px 0px 30px #6ab8a5;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill-red {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

/**
  Toast
*/

.Toastify__toast {
  @apply rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 text-sm font-medium;
}

.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #3fa68d;
}

.Toastify__toast--error .Toastify__toast-icon svg {
  @apply fill-error;
}


.markdown-wrapper {
  /* Typography */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  line-height: 1.5;
  color: #1a1a1a;
  
  /* Word break */
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;

  /* Paragraphs */
  p {
    font-size: 14px;
    font-weight: 450;
    color: #4b5563;
    margin-bottom: 8px;
  }

  /* Lists */
  ul, ol {
    font-size: 14px;
    font-weight: 450;
    color: #4b5563;
    margin: 8px 0;
    padding-left: 8px;
  }

  ul {
    list-style: none;

    li {
      position: relative;
      padding-left: 12px;
      margin-bottom: 8px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 7px;
        width: 5px;
        height: 5px;
        background-color: #9ca3af;
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: item;
  
    li {
      position: relative;
      padding-left: 12px;
      margin-bottom: 8px;
  
      &::before {
        content: counter(item) ".";
        counter-increment: item;
        position: absolute;
        left: -4px;
        color: #9ca3af;
        font-weight: 450;
      }
    }
  }  

  /* Links */
  a {
    text-decoration: underline;

    &:hover {
      color: black;
    }
  }

  /* Content Spacing */
  > * + * {
    margin-top: 1.5rem;
  }

  /* Secondary Text */
  .secondary-text {
    color: #6b7280;
    font-size: 1.125rem;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .markdown-wrapper {
    color: #e5e7eb;
    
    p {
      color: #d1d5db;
    }
    
    ul, ol {
      color: #d1d5db;
    }
    
    ul li::before {
      background-color: #6b7280;
    }
    
    ol li::before {
      color: #6b7280;
    }
    
    a {
      color: #93c5fd;
      
      &:hover {
        color: #bfdbfe;
      }
    }
    
    .secondary-text {
      color: #9ca3af;
    }
  }
}

/* Alternative Dark Mode Class for Manual Toggle */
.dark .markdown-wrapper {
  color: #e5e7eb;
  
  p {
    color: #d1d5db;
  }
  
  ul, ol {
    color: #d1d5db;
  }
  
  ul li::before {
    background-color: #6b7280;
  }
  
  ol li::before {
    color: #6b7280;
  }
  
  a {
    color: #93c5fd;
    
    &:hover {
      color: #bfdbfe;
    }
  }
  
  .secondary-text {
    color: #9ca3af;
  }
}